import { ReactComponent as Chart } from 'app-images/icons/layout-chart.svg'
import { ReactComponent as Full } from 'app-images/icons/layout-full.svg'
import { ReactComponent as Left } from 'app-images/icons/layout-left.svg'
import { ReactComponent as Right } from 'app-images/icons/layout-right.svg'
import { FC, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { SingleValue } from 'react-select'

import { updateSettings } from '../actions'
import { AmplitudeEvent } from '../constants/amplitudeEvents'
import { State } from '../reducers'
import amplitudeService from '../services/amplitudeService'

interface LayoutSelectorLayout {
  left: boolean
  right: boolean
}

interface LayoutSelectorLayoutProps {
  isMarketTypeToken: boolean
}

interface LayoutSelectorItemType {
  value: string
  amplitudePageLayout: string
  layout: LayoutSelectorLayout
  label: ReactElement
  formVisible: boolean
}

const LayoutSelector: FC<LayoutSelectorLayoutProps> = (props: LayoutSelectorLayoutProps) => {
  const { isMarketTypeToken } = props

  const optionsFull = isMarketTypeToken
    ? [
        {
          value: 'full',
          amplitudePageLayout: 'default',
          formVisible: true,
          layout: { left: true, right: true },
          label: (
            <span className="icon">
              <Full />
            </span>
          ),
        },
        {
          value: 'right',
          amplitudePageLayout: 'trading sidebar only',
          formVisible: true,
          layout: { left: false, right: true },
          label: (
            <span className="icon">
              <Right />
            </span>
          ),
        },
      ]
    : []

  const options: LayoutSelectorItemType[] = [
    {
      value: 'left',
      amplitudePageLayout: 'liquidity sidebar only',
      formVisible: true,
      layout: { left: true, right: false },
      label: (
        <span className="icon">
          <Left />
        </span>
      ),
    },
    {
      value: 'chart',
      amplitudePageLayout: 'price chart only',
      formVisible: false,
      layout: { left: false, right: false },
      label: (
        <span className="icon">
          <Chart />
        </span>
      ),
    },
    ...optionsFull,
  ]
  const reduxDispatch = useDispatch()
  const { isLeftDrawerOpen, isRightDrawerOpen, tvChartHeight, isChartFullScreen } = useSelector(
    (state: State) => state.settings.settingsData
  )
  const onChange = (value: SingleValue<LayoutSelectorItemType>): void => {
    if (!value?.layout) {
      return
    }

    amplitudeService.changedTokenPageLayout(value.amplitudePageLayout)

    Object.entries(value.layout).forEach((entry) => {
      const [key, value] = entry
      reduxDispatch(
        updateSettings({ [key === 'left' ? 'isLeftDrawerOpen' : 'isRightDrawerOpen']: value })
      )
    })

    reduxDispatch(
      updateSettings({
        isChartFullScreen: value.value === 'chart',
        tvChartHeight: value.value !== 'chart' ? tvChartHeight : '',
      })
    )
  }

  const clearStyles = new Proxy(
    {},
    {
      get: () => (): void => {
        // do nothing
      },
    }
  )

  const current = options.find(
    (option) =>
      option.layout.left === isLeftDrawerOpen &&
      option.layout.right === isRightDrawerOpen &&
      option.formVisible === !isChartFullScreen
  )

  let valueIdx = 0

  if (current) {
    const idx = options.indexOf(current)
    valueIdx = idx < 0 ? 0 : idx
  }

  const onMenuOpen = (): void => {
    amplitudeService.sendEvent(AmplitudeEvent.OPENED_CHANGE_PAGE_LAYOUT)
  }

  return (
    <Select
      options={options}
      className="layout-selector"
      classNamePrefix="layout-selector"
      onChange={onChange}
      value={options[valueIdx]}
      isMulti={false}
      isSearchable={false}
      closeMenuOnScroll={false}
      styles={clearStyles}
      onMenuOpen={onMenuOpen}
    />
  )
}

export default LayoutSelector
